<template>
  <v-app id="inspire">
    <div  >
      <div class="filter-informe-eventos">
        <div class="container-title-informe">
          <h3><i class="fas fa-chart-line pr-2"></i>Generar informe de Ventas por evento</h3>
        </div>
        <div class="col-12 col-md-4">
          <v-select
            v-model="evento"
            item-value="id"
            item-text="nombre"
            :items="$store.state.informes.eventosInforme"
            label="Evento"
          ></v-select>
        </div>
       
       
       
        <div class="col-12 col-md-3" v-if="[1, 3, 7, 11].includes($store.state.user.dataUserLogged.id_rol)">
          <v-menu
            :nudge-right="40"
            lazy
            ref="menu1"
            transition="scale-transition"
            offset-y
            full-width
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="dateDesde"
                label="Fecha desde"
                prepend-icon="event"
                readonly
                clearable
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="dateDesde" @click:minute="$refs.menu1.save(dateDesde)" locale="es-ES"></v-date-picker>
          </v-menu>
        </div>
        <div class="col-12 col-md-3" v-if="[1, 3, 7, 11].includes($store.state.user.dataUserLogged.id_rol)">
          <v-menu
            :nudge-right="40"
            ref="menu2"
            lazy
            transition="scale-transition"
            offset-y
            full-width
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="dateHasta"
                label="Fecha hasta"
                prepend-icon="event"
                readonly
                clearable
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="dateHasta" @click:minute="$refs.menu2.save(dateHasta)" locale="es-ES"></v-date-picker>
          </v-menu>
        </div>
       
        <div class="col-12 col-md-2 d-flex flex-wrap align-items-center">
          <button class="btn btn-primary" :disabled="!(evento != null)" @click="getInformeDeEventos()">BUSCAR</button>
        </div>
      </div>
  </div>
  <div class="row" v-if="inforeporteres" >
    <div class="col-lg-6 col-sm-6">
        <div class="card">
          <div class="header"><h2> Recaudo Total:    </h2></div>
          <div class="tableBody">
            <div class="table-responsive"> 

              <table id="recaudototal" class="table table-hover dashboard-task-infos">
                <tr>
                    <td>Pagos en Efectivo     </td>
                    <td style="text-align: right; font-weight: bold;"><strong>  $ {{  formatoNumero($store.state.datosreporte.ventas.monto_efectivo)  }} </strong></td>
                </tr>
                <tr>
                    <td>Pagos en Tarjeta</td>
                    <td style="text-align: right; font-weight: bold;"><strong>  $ {{   formatoNumero($store.state.datosreporte.ventas.monto_tarjeta ) }}  </strong></td>
                </tr>
                <tr>
                    <td>Pagos   Mixtos</td>
                    <td style="text-align: right; font-weight: bold;"><strong>  $ {{   formatoNumero($store.state.datosreporte.ventas.monto_mixto)  }}  </strong></td>
                </tr>
                <tr>
                    <td>Empresario</td>
                    <td style="text-align: right; font-weight: bold;"><strong>  $ {{   formatoNumero($store.state.datosreporte.ventas.monto_empresario)  }}  </strong></td>
                </tr>
                <tr v-if="!esRolPermitido">
                    <!---->
                    <td>  Total </td>
                    <td style="text-align: right; font-weight: bold;"><strong>  $ {{   formatoNumero(totalsinservivio)  }}</strong></td>
                </tr>
                <tr v-if="esRolPermitido">
                    <!---->
                    <td> Sub Total Sin Servicio</td>
                    <td style="text-align: right; font-weight: bold;"><strong>  $ {{   formatoNumero(totalsinservivio)  }}</strong></td>
                </tr>
                <tr v-if="esRolPermitido">
                    <td>Valor Servicio</td>
                    <td style="text-align: right; font-weight: bold;"><strong>  $ {{   formatoNumero($store.state.datosreporte.ventaservicios.valor)  }}  </strong></td>
                </tr>
                <tr v-if="esRolPermitido">
                    <td >Total Con Servicio</td>
                    <td style="text-align: right; font-weight: bold;"><strong>  $ {{     formatoNumero( parseFloat(totalsinservivio)+parseFloat( $store.state.datosreporte.ventaservicios.valor ) )  }}  </strong></td>
                </tr>
              </table>

            </div>
          </div>
        </div>
     </div>

     <div class="col-lg-6 col-sm-6">
        <div class="card">
          <div class="header"><h2> Valores Informativos:    </h2></div>
          <div class="tableBody">
            <div class="table-responsive"> 
              <table id="vinformativos" class="table table-hover dashboard-task-infos">
              <tr v-if="esRolPermitido">
                  <td >Descuento </td>
                  <td style="text-align: right; font-weight: bold;"><strong> $  {{   formatoNumero( $store.state.datosreporte.ventas.descuento ) }}    </strong></td>
              </tr>
              <tr  v-if="esRolPermitido">
                  <td>Costo transaccion</td>
                  <td style="text-align: right; font-weight: bold;"><strong>  $ {{   formatoNumero( $store.state.datosreporte.ventas.costo_transaccion)  }} </strong></td>
              </tr>
              <tr>
                  <td>Cortesias</td>
                  <td style="text-align: right; font-weight: bold;"><strong> {{   formatoNumero(cortesias) }}  </strong></td>
              </tr>
            </table> 
            </div>
          </div>
        </div>
     </div>



</div>

<v-tabs v-model="activeTab" v-if="inforeporteres"  >
    <v-tab>RESUMEN</v-tab>
    <v-tab>DETALLE</v-tab>
    <v-tab>AFORO OCUPADO</v-tab>

    <v-tab-item>
      <v-card>
        <v-card-text>
              <div class="col-lg-6 col-sm-6">
                  <div class="card">
                     <div class="tableBody">
                      <div class="table-responsive"> 
                            


                        <table id="tresumen" class="table table-hover dashboard-task-infos">
   <thead>
      <tr style="font-size: 10px;">
         <th style="background: rgb(244, 244, 244); font-weight: bold;"> Tribuna   </th>
         <th style="background: rgb(244, 244, 244); font-weight: bold;">  Localidad </th>
         <th style="background: rgb(244, 244, 244); font-weight: bold; text-align: right;"> Valor Unitario   </th>
         <th style="background: rgb(244, 244, 244); font-weight: bold; text-align: right;"  v-if="esRolPermitido"> Desc. Unitario   </th>
         <th style="background: rgb(244, 244, 244); font-weight: bold; text-align: right;">  Total Unidades Vendidas        </th>
         <th style="background: rgb(244, 244, 244); font-weight: bold; text-align: right;">  Total     </th>
      </tr>
   </thead>



   <tr   v-for="(item, index) in  $store.state.datosreporte.palcosresumen" :key="index">
      <td style="font-size: 10px;">   {{ item.tribuna }} </td>
      <td style="font-size: 10px;">   {{ item.localidad }} </td>
      <td style="text-align: right; font-size: 10px;">$  {{   formatoNumero(item.precio_venta) }}  </td>
      <td style="text-align: right; font-size: 10px;"  v-if="esRolPermitido">   {{   formatoNumero(item.monto_descuento) }}  </td>
      <td style="text-align: right; font-size: 10px;">   {{   formatoNumero(item.Pagos_total) }}  </td>
      <td style="text-align: right; font-size: 10px;">$  {{   formatoNumero(item.SumaDeprecio_venta) }}   </td>
   </tr>
 <thead>
      <tr style="font-size: 10px;">
         <th style="background: rgb(244, 244, 244); font-weight: bold;">  TOTAL </th>
         <th style="background: rgb(244, 244, 244); font-weight: bold;"></th>
         <th style="background: rgb(244, 244, 244); font-weight: bold;"></th>
         <th style="background: rgb(244, 244, 244); font-weight: bold;"  v-if="esRolPermitido"></th>
         <th style="background: rgb(244, 244, 244); font-weight: bold; text-align: right;"> {{  formatoNumero( totalvalorinfo1) }}  </th>
         <th style="background: rgb(244, 244, 244); font-weight: bold; text-align: right;">$ {{   formatoNumero(totalvalorinfo2) }} </th>
      </tr>
   </thead>
</table>







                      </div>
                    </div>
                  </div>
              </div>
        </v-card-text>
      </v-card>
    </v-tab-item>

    <v-tab-item>
      <v-card>
        <v-card-text>
          <div class="col-lg-12 col-sm-12">
                  <div class="card">
                    <div class="header"><h2><strong>  DETALLE </strong> VENTA INDIVIDUALES </h2></div>
                    <div class="tableBody">
                      <div class="table-responsive"> 
   <table  class="table table-hover dashboard-task-infos">
   <thead>
      <tr style="font-size: 10px;">
         <th style="background: rgb(244, 244, 244); font-weight: bold; text-align: right;"> Tribuna   </th>
         <th style="background: rgb(244, 244, 244); font-weight: bold;">  Localidad </th>
         <th style="background: rgb(244, 244, 244); font-weight: bold; text-align: right;"> Valor Unitario   </th>
         <th style="background: rgb(244, 244, 244); font-weight: bold; text-align: right;"  v-if="esRolPermitido"> Desc. Unitario   </th>
         <th style="background: rgb(244, 244, 244); font-weight: bold; text-align: right;">  Unidades Vendidas  Efectivo     </th>
         <th style="background: rgb(244, 244, 244); font-weight: bold; text-align: right;">  Total Efectivo     </th>
         <th style="background: rgb(244, 244, 244); font-weight: bold; text-align: right;">  Unidades Vendidas  Tarjeta     </th>
         <th style="background: rgb(244, 244, 244); font-weight: bold; text-align: right;">  Total  Tarjeta    </th>
         <th style="background: rgb(244, 244, 244); font-weight: bold; text-align: right;">  Unidades Vendidas   Mixto     </th>
         <th style="background: rgb(244, 244, 244); font-weight: bold; text-align: right;">  Total Mixto    </th>
         <th style="background: rgb(244, 244, 244); font-weight: bold; text-align: right;">  Unidades Vendidas   Empresario     </th>
         <th style="background: rgb(244, 244, 244); font-weight: bold; text-align: right;">  Total Empresario    </th>
         <th style="background: rgb(244, 244, 244); font-weight: bold; text-align: right;">  Unidades Cortesia    </th>
         <th style="background: rgb(244, 244, 244); font-weight: bold; text-align: right;">  Total Unidades Vendidas        </th>
         <th style="background: rgb(244, 244, 244); font-weight: bold; text-align: right;">  Total     </th>
      </tr>
   </thead>
   <tr   v-for="(item, index) in  $store.state.datosreporte.individual" :key="index">
      <td style="font-size: 10px;"> {{ item.tribuna }}</td>
      <td style="font-size: 10px;"> {{ item.localidad }}</td>
      <td style="text-align: right; font-size: 10px;">${{   formatoNumero(item.precio_venta) }}</td> 
      <td style="text-align: right; font-size: 10px;"  v-if="esRolPermitido">{{   formatoNumero(item.monto_descuento) }}</td>
      <td style="text-align: right; font-size: 10px;">{{   formatoNumero(item.Pagos_efectivo) }}</td>
      <td style="text-align: right; font-size: 10px;">${{   formatoNumero(item.valor_efectivo) }}</td>
      <td style="text-align: right; font-size: 10px;">{{   formatoNumero(item.Pagos_tarjeta) }}</td>
      <td style="text-align: right; font-size: 10px;">${{   formatoNumero(item.valor_tarjeta) }}</td>
      <td style="text-align: right; font-size: 10px;">{{   formatoNumero(item.Pagos_mixto) }}</td>
      <td style="text-align: right; font-size: 10px;">${{   formatoNumero(item.valor_mixto) }}</td>
      <td style="text-align: right; font-size: 10px;">{{   formatoNumero(item.Pagos_empresario) }}</td>
      <td style="text-align: right; font-size: 10px;">${{   formatoNumero(item.valor_empresario) }}</td>
      <td style="text-align: right; font-size: 10px;">{{   formatoNumero(item.Pagos_cortesia )}}</td>
      <td style="text-align: right; font-size: 10px;">{{   formatoNumero(item.Pagos_total ) }}</td>
      <td style="text-align: right; font-size: 10px;">${{   formatoNumero(item.total) }}</td>
   </tr>
   
   <thead>
      <tr style="font-size: 10px;">
         <th style="background: rgb(244, 244, 244); font-weight: bold;">  TOTAL </th>
         <th style="background: rgb(244, 244, 244); font-weight: bold;"></th>
         <th style="background: rgb(244, 244, 244); font-weight: bold;"></th>
         <th style="background: rgb(244, 244, 244); font-weight: bold;"  v-if="esRolPermitido"></th>
         <th style="background: rgb(244, 244, 244); font-weight: bold; text-align: right;">  {{  formatoNumero( tindi[0] ) }}  </th>
         <th style="background: rgb(244, 244, 244); font-weight: bold; text-align: right;">$ {{  formatoNumero( tindi[1]) }}</th>
         <th style="background: rgb(244, 244, 244); font-weight: bold; text-align: right;">  {{  formatoNumero( tindi[2]) }}  </th>
         <th style="background: rgb(244, 244, 244); font-weight: bold; text-align: right;">$ {{   formatoNumero(tindi[3]) }}</th>
         <th style="background: rgb(244, 244, 244); font-weight: bold; text-align: right;">  {{  formatoNumero( tindi[4]) }}  </th>
         <th style="background: rgb(244, 244, 244); font-weight: bold; text-align: right;">$ {{  formatoNumero( tindi[5]) }}</th>
         <th style="background: rgb(244, 244, 244); font-weight: bold; text-align: right;">  {{  formatoNumero( tindi[6]) }}  </th>
         <th style="background: rgb(244, 244, 244); font-weight: bold; text-align: right;">$ {{   formatoNumero(tindi[7]) }}</th>
         <th style="background: rgb(244, 244, 244); font-weight: bold; text-align: right;">  {{   formatoNumero(tindi[8]) }}  </th>
         <th style="background: rgb(244, 244, 244); font-weight: bold; text-align: right;">  {{   formatoNumero(tindi[9]) }}  </th>
         <th style="background: rgb(244, 244, 244); font-weight: bold; text-align: right;">$ {{   formatoNumero(tindi[10]) }}</th>
      </tr>
   </thead>
</table>
                      </div>
                    </div>
                  </div>
              </div>         
              <div class="col-lg-12 col-sm-12">
                  <div class="card">
                    <div class="header"><h2><strong>  DETALLE </strong> VENTA PALCOS </h2></div>
                    <div class="tableBody">
                      <div class="table-responsive"> 




   <table   class="table table-hover dashboard-task-infos">
   <thead>
      <tr style="font-size: 10px;">
         <th style="background: rgb(244, 244, 244); font-weight: bold; text-align: right;"> Tribuna   </th>
         <th style="background: rgb(244, 244, 244); font-weight: bold;">  Localidad </th>
         <th style="background: rgb(244, 244, 244); font-weight: bold; text-align: right;"> Valor Unitario   </th>
         <th style="background: rgb(244, 244, 244); font-weight: bold; text-align: right;"  v-if="esRolPermitido"> Desc. Unitario   </th>
         <th style="background: rgb(244, 244, 244); font-weight: bold; text-align: right;">  Unidades Vendidas  Efectivo     </th>
         <th style="background: rgb(244, 244, 244); font-weight: bold; text-align: right;">  Total Efectivo     </th>
         <th style="background: rgb(244, 244, 244); font-weight: bold; text-align: right;">  Unidades Vendidas  Tarjeta     </th>
         <th style="background: rgb(244, 244, 244); font-weight: bold; text-align: right;">  Total  Tarjeta    </th>
         <th style="background: rgb(244, 244, 244); font-weight: bold; text-align: right;">  Unidades Vendidas   Mixto     </th>
         <th style="background: rgb(244, 244, 244); font-weight: bold; text-align: right;">  Total Mixto    </th>
         <th style="background: rgb(244, 244, 244); font-weight: bold; text-align: right;">  Unidades Vendidas   Empresario     </th>
         <th style="background: rgb(244, 244, 244); font-weight: bold; text-align: right;">  Total Empresario    </th>
         <th style="background: rgb(244, 244, 244); font-weight: bold; text-align: right;">  Unidades Cortesia    </th>
         <th style="background: rgb(244, 244, 244); font-weight: bold; text-align: right;">  Total Unidades Vendidas        </th>
         <th style="background: rgb(244, 244, 244); font-weight: bold; text-align: right;">  Total     </th>
      </tr>
   </thead>
   <tr   v-for="(item, index) in  $store.state.datosreporte.palcos" :key="index">
      <td style="font-size: 10px;">{{ item.tribuna }}</td>
      <td style="font-size: 10px;">{{ item.localidad }}</td>
      <td style="text-align: right; font-size: 10px;">${{   formatoNumero(item.precio_venta) }}</td>
      <td style="text-align: right; font-size: 10px;"  v-if="esRolPermitido">{{   formatoNumero(item.monto_descuento) }}</td>
      <td style="text-align: right; font-size: 10px;">{{   formatoNumero(item.Pagos_efectivo) }}</td>
      <td style="text-align: right; font-size: 10px;">${{  formatoNumero( item.valor_efectivo) }}</td>
      <td style="text-align: right; font-size: 10px;">{{   formatoNumero(item.Pagos_tarjeta) }}</td>
      <td style="text-align: right; font-size: 10px;">${{  formatoNumero( item.valor_tarjeta) }}</td>
      <td style="text-align: right; font-size: 10px;">{{   formatoNumero(item.Pagos_mixto) }}</td>
      <td style="text-align: right; font-size: 10px;">${{  formatoNumero( item.valor_mixto) }}</td>
      <td style="text-align: right; font-size: 10px;">{{   formatoNumero(item.Pagos_empresario) }}</td>
      <td style="text-align: right; font-size: 10px;">${{   formatoNumero(item.valor_empresario) }}</td>
      <td style="text-align: right; font-size: 10px;">{{   formatoNumero(item.Pagos_cortesia) }}</td>
      <td style="text-align: right; font-size: 10px;">{{   formatoNumero(item.Pagos_total) }}</td>
      <td style="text-align: right; font-size: 10px;">${{   formatoNumero(item.total) }}</td>
   </tr>
 
   <thead>
      <tr style="font-size: 10px;">
         <th style="background: rgb(244, 244, 244); font-weight: bold;">  TOTAL </th>
         <th style="background: rgb(244, 244, 244); font-weight: bold;"></th>
         <th style="background: rgb(244, 244, 244); font-weight: bold;"></th>
         <th style="background: rgb(244, 244, 244); font-weight: bold;"  v-if="esRolPermitido"></th>
         <th style="background: rgb(244, 244, 244); font-weight: bold; text-align: right;"> {{   formatoNumero(tpalco[0]) }}  </th>
         <th style="background: rgb(244, 244, 244); font-weight: bold; text-align: right;">$ {{   formatoNumero(tpalco[1]) }}</th>
         <th style="background: rgb(244, 244, 244); font-weight: bold; text-align: right;">  {{   formatoNumero(tpalco[2]) }}  </th>
         <th style="background: rgb(244, 244, 244); font-weight: bold; text-align: right;">$ {{   formatoNumero(tpalco[3]) }}</th>
         <th style="background: rgb(244, 244, 244); font-weight: bold; text-align: right;">  {{   formatoNumero(tpalco[4]) }}  </th>
         <th style="background: rgb(244, 244, 244); font-weight: bold; text-align: right;">$  {{   formatoNumero(tpalco[5]) }}</th>
         <th style="background: rgb(244, 244, 244); font-weight: bold; text-align: right;">  {{   formatoNumero(tpalco[6]) }}  </th>
         <th style="background: rgb(244, 244, 244); font-weight: bold; text-align: right;">$  {{   formatoNumero(tpalco[7]) }}</th>
         <th style="background: rgb(244, 244, 244); font-weight: bold; text-align: right;">  {{   formatoNumero(tpalco[8]) }}  </th>
         <th style="background: rgb(244, 244, 244); font-weight: bold; text-align: right;">  {{   formatoNumero(tpalco[9]) }}  </th>
         <th style="background: rgb(244, 244, 244); font-weight: bold; text-align: right;">$  {{   formatoNumero(tpalco[10]) }}</th>
      </tr>
   </thead>
</table>











                            
                      </div>
                    </div>
                  </div>
              </div>         
              <div class="col-lg-12 col-sm-12">
                  <div class="card">
                    <div class="header"><h2><strong>  DETALLE </strong> VENTA PALCOS INDIVIDUALES </h2></div>
                    <div class="tableBody">
                      <div class="table-responsive"> 




                        <table id="tdetalle3" class="table table-hover dashboard-task-infos">
   <thead>
      <tr style="font-size: 10px;">
         <th style="background: rgb(244, 244, 244); font-weight: bold; text-align: right;"> Tribuna   </th>
         <th style="background: rgb(244, 244, 244); font-weight: bold;">  Localidad </th>
         <th style="background: rgb(244, 244, 244); font-weight: bold; text-align: right;"> Valor Unitario   </th>
         <th style="background: rgb(244, 244, 244); font-weight: bold; text-align: right;"  v-if="esRolPermitido"> Desc. Unitario   </th>
         <th style="background: rgb(244, 244, 244); font-weight: bold; text-align: right;">  Unidades Vendidas Efectivo     </th>
         <th style="background: rgb(244, 244, 244); font-weight: bold; text-align: right;">  Total Efectivo     </th>
         <th style="background: rgb(244, 244, 244); font-weight: bold; text-align: right;">  Unidades Vendidas  Tarjeta     </th>
         <th style="background: rgb(244, 244, 244); font-weight: bold; text-align: right;">  Total  Tarjeta    </th>
         <th style="background: rgb(244, 244, 244); font-weight: bold; text-align: right;">  Unidades Vendidas   Mixto     </th>
         <th style="background: rgb(244, 244, 244); font-weight: bold; text-align: right;">  Total Mixto    </th>
         <th style="background: rgb(244, 244, 244); font-weight: bold; text-align: right;">  Unidades Vendidas   Empresario     </th>
         <th style="background: rgb(244, 244, 244); font-weight: bold; text-align: right;">  Total Empresario    </th>
         <th style="background: rgb(244, 244, 244); font-weight: bold; text-align: right;">  Unidades Cortesia    </th>
         <th style="background: rgb(244, 244, 244); font-weight: bold; text-align: right;">  Total Unidades Vendidas        </th>
         <th style="background: rgb(244, 244, 244); font-weight: bold; text-align: right;">  Total     </th>
      </tr>
   </thead>
   <tr   v-for="(item, index) in  $store.state.datosreporte.palcosindividual" :key="index">
      <td style="font-size: 10px;">{{ item.tribuna }}</td>
      <td style="font-size: 10px;">{{ item.localidad }}</td>
      <td style="text-align: right; font-size: 10px;">${{   formatoNumero(item.precio_venta) }}</td>
      <td style="text-align: right; font-size: 10px;"  v-if="esRolPermitido">{{   formatoNumero(item.monto_descuento) }}</td>
      <td style="text-align: right; font-size: 10px;">{{   formatoNumero(item.Pagos_efectivo) }}</td>
      <td style="text-align: right; font-size: 10px;">${{   formatoNumero(item.valor_efectivo) }}</td>
      <td style="text-align: right; font-size: 10px;">{{   formatoNumero(item.Pagos_tarjeta) }}</td>
      <td style="text-align: right; font-size: 10px;">${{   formatoNumero(item.valor_tarjeta) }}</td>
      <td style="text-align: right; font-size: 10px;">{{   formatoNumero(item.Pagos_mixto) }}</td>
      <td style="text-align: right; font-size: 10px;">${{   formatoNumero(item.valor_mixto) }}</td>
      <td style="text-align: right; font-size: 10px;">{{   formatoNumero(item.Pagos_empresario) }}</td>
      <td style="text-align: right; font-size: 10px;">${{   formatoNumero(item.valor_empresario ) }}</td>
      <td style="text-align: right; font-size: 10px;">{{   formatoNumero(item.Pagos_cortesia) }}</td>
      <td style="text-align: right; font-size: 10px;">{{   formatoNumero(item.Pagos_total) }}</td>
      <td style="text-align: right; font-size: 10px;">${{   formatoNumero(item.total) }}</td>
   </tr>
   <thead>
      <tr style="font-size: 10px;">
         <th style="background: rgb(244, 244, 244); font-weight: bold;">  TOTAL </th>
         <th style="background: rgb(244, 244, 244); font-weight: bold;"></th>
         <th style="background: rgb(244, 244, 244); font-weight: bold;"></th>
         <th style="background: rgb(244, 244, 244); font-weight: bold;"  v-if="esRolPermitido"></th>
         <th style="background: rgb(244, 244, 244); font-weight: bold; text-align: right;"> {{   formatoNumero(tpalcoindt[0]) }}  </th>
         <th style="background: rgb(244, 244, 244); font-weight: bold; text-align: right;">$ {{   formatoNumero(tpalcoindt[1]) }}</th>
         <th style="background: rgb(244, 244, 244); font-weight: bold; text-align: right;">  {{   formatoNumero(tpalcoindt[2]) }}  </th>
         <th style="background: rgb(244, 244, 244); font-weight: bold; text-align: right;">$ {{   formatoNumero(tpalcoindt[3]) }}</th>
         <th style="background: rgb(244, 244, 244); font-weight: bold; text-align: right;">  {{   formatoNumero(tpalcoindt[4]) }}  </th>
         <th style="background: rgb(244, 244, 244); font-weight: bold; text-align: right;">$  {{   formatoNumero(tpalcoindt[5]) }}</th>
         <th style="background: rgb(244, 244, 244); font-weight: bold; text-align: right;">  {{   formatoNumero(tpalcoindt[6]) }}  </th>
         <th style="background: rgb(244, 244, 244); font-weight: bold; text-align: right;">$  {{   formatoNumero(tpalcoindt[7]) }}</th>
         <th style="background: rgb(244, 244, 244); font-weight: bold; text-align: right;">  {{   formatoNumero(tpalcoindt[8]) }}  </th>
         <th style="background: rgb(244, 244, 244); font-weight: bold; text-align: right;">  {{   formatoNumero(tpalcoindt[9]) }}  </th>
         <th style="background: rgb(244, 244, 244); font-weight: bold; text-align: right;">$  {{   formatoNumero(tpalcoindt[10]) }}</th>
      </tr>
   </thead>
</table>
                            
                      </div>
                    </div>
                  </div>
              </div>


        </v-card-text>
      </v-card>
    </v-tab-item>

    <v-tab-item>
      <v-card>
        <v-card-text>
          
          <div class="row"> 
          <div class="col-lg-6 col-sm-6">

            <div class="counter-box text-center white">
                <div class="text font-17 m-b-5">AFORO OCUPADO</div>
                <h3 class="m-b-10">    {{    formatoNumero($store.state.datosreporte.taforo)  }}   </h3>
                <div class="icon"><i class="material-icons col-green">trending_up</i></div>
              </div>




                   
              </div>
              <div class="col-lg-6 col-sm-6">


                <div class="counter-box text-center white">
                  <div class="text font-17 m-b-5"> % AFORO  OCUPADO  </div>
                  <h3 class="m-b-10">    {{    formatoNumero($store.state.datosreporte.tporcentaje ) }}   %   </h3>
                  <div class="icon"><i class="material-icons col-green">trending_up</i></div>
                </div>


                   
              </div>
            </div>
              <div class="col-lg-12 col-sm-12">
                  <div class="card">
                    <div class="header"><h2><strong>AFORO OCUPADO</strong> EVENTO</h2></div>
                    <div class="tableBody">
                      <div class="table-responsive"> 
                        <table  class="  table">
   <thead>
      <tr style="font-size: 16px;">
         <th style="background: rgb(244, 244, 244); font-weight: bold;">  TRIBUNA </th>
         <th style="background: rgb(244, 244, 244); font-weight: bold;">  LOCALIDAD </th>
         <th style="background: rgb(244, 244, 244); font-weight: bold; text-align: right;">  AFORO OCUPADO   </th>
         <th style="background: rgb(244, 244, 244); font-weight: bold; text-align: right;">  TOTAL AFORO   </th>
         <th style="background: rgb(244, 244, 244); font-weight: bold; text-align: right;">  % AFORO  OCUPADO  </th>
      </tr>
   </thead>

   <tr   v-for="(item, index) in  $store.state.datosreporte.aforonew" :key="index">
      <td>
         <div class="p-t-10"><span class="pull-left progress-label">  {{ item.tribuna }} </span></div>
      </td>
      <td>
         <div class="p-t-10"><span class="pull-left progress-label">   {{ item.localidad }}  </span></div>
      </td>
      <td style="text-align: right;">    {{ item.aforo }}  </td>
      <td style="text-align: right;"><span class="pull-right progress-percent label label-info m-b-5">   {{   formatoNumero(item.aforohabilitado) }}  </span></td>
      <td style="text-align: right;"><span class="pull-right progress-percent label label-info m-b-5">  {{   formatoNumero( ( parseFloat(item.aforo) / parseFloat(item.aforohabilitado)) *100)  }}  </span></td>
   </tr>



  
   <tr>
      <td style="background: rgb(223, 255, 239); font-weight: bold;">   TOTAL </td>
      <td style="background: rgb(223, 255, 239); font-weight: bold;"></td>
      <td style="background: rgb(223, 255, 239); font-weight: bold; text-align: right;"><span class="label bg-green shadow-style"> {{   formatoNumero($store.state.datosreporte.aforohabilitado) }} </span></td>
      <td style="background: rgb(223, 255, 239); font-weight: bold; text-align: right;"><span class="label bg-green shadow-style"> {{   formatoNumero($store.state.datosreporte.taforo ) }}</span></td>
      <td style="background: rgb(223, 255, 239); font-weight: bold; text-align: right;"><span class="label bg-green shadow-style"> {{   formatoNumero($store.state.datosreporte.tporcentaje) }}    </span></td>
   </tr>
</table>    
                      </div>
                    </div>
                  </div>
              </div>



        </v-card-text>
      </v-card>
    </v-tab-item>

    
  </v-tabs>

  <br><br><br><br><br>
  </v-app>
</template>
<script>
import numeral from 'numeral';
export default {
  data() {
    return {
      activeTab: null,
      evento: null, 
      dateDesde: '',
      menu1: false,
      dateHasta: '',
      menu2: false,
      search: '', 
      inforeporteres:false,
      cortesias:0, 
      totalsinservivio:0,
      totalvalorinfo1:0,
      totalvalorinfo2:0,
      vcalculo:0,

      tindi:[],
      tpalco:[],
      tpalcoindt:[],



    }

  },
  computed: {
    listadoLocalidades(){
      return this.evento ? this.$store.state.informes.eventosInforme.find((e) => e.id == this.evento).localidades : []
    },
    listadoPuntosVenta(){
      return this.evento ? this.$store.state.informes.eventosInforme.find((e) => e.id == this.evento).puntos_venta : []
    },
    esRolPermitido() {
      const rolesPermitidos = [1, 2,4,5,6,7,8,9,10];
      return rolesPermitidos.includes(this.rol);
    }
  },
  mounted(){
    this.$store.state.datosreporte = null 
    this.$store.dispatch('informes/getEventos');
    document.title = 'Informe ventas evento - TicketShop';
  },
  methods:{
     formatoNumero(numero) {
      return numeral(numero).format('0,0');
    },
    async getInformeDeEventos(){
      if(this.evento){
        await this.$store.dispatch('informes/getInformesEvento', {
          id_evento: this.evento,
          fecha_desde: this.dateDesde ? this.dateDesde : '',
          fecha_hasta: this.dateHasta ? this.dateHasta : '', 
        });
        this.inforeporteres=true;
        this.cortesias=0; 
        this.totalsinservivio=0;
        this.totalvalorinfo1=0;
        this.totalvalorinfo2=0;
          this.tindi[0]=0; 
          this.tindi[1]=0; 
          this.tindi[2]=0; 
          this.tindi[3]=0; 
          this.tindi[4]=0; 
          this.tindi[5]=0; 
          this.tindi[6]=0; 
          this.tindi[7]=0; 
          this.tindi[8]=0; 
          this.tindi[9]=0; 
          this.tindi[10]=0;  

          this.tpalco[0]=0; 
          this.tpalco[1]=0; 
          this.tpalco[2]=0; 
          this.tpalco[3]=0; 
          this.tpalco[4]=0; 
          this.tpalco[5]=0; 
          this.tpalco[6]=0; 
          this.tpalco[7]=0; 
          this.tpalco[8]=0; 
          this.tpalco[9]=0; 
          this.tpalco[10]=0;  

          this.tpalcoindt[0]=0; 
          this.tpalcoindt[1]=0; 
          this.tpalcoindt[2]=0; 
          this.tpalcoindt[3]=0; 
          this.tpalcoindt[4]=0; 
          this.tpalcoindt[5]=0; 
          this.tpalcoindt[6]=0; 
          this.tpalcoindt[7]=0; 
          this.tpalcoindt[8]=0; 
          this.tpalcoindt[9]=0; 
          this.tpalcoindt[10]=0;  


        this.$store.state.datosreporte.aforonew.forEach((item, index) => {
          this.cortesias= this.cortesias+parseFloat(item.Pagos_cortesia); 
       });
       this.$store.state.datosreporte.palcosresumen.forEach((item, index) => {
          this.totalvalorinfo1= this.totalvalorinfo1+parseFloat(item.Pagos_total); 
          this.totalvalorinfo2= this.totalvalorinfo2+parseFloat(item.SumaDeprecio_venta); 
       });

       
       this.$store.state.datosreporte.individual.forEach((item, index) => {
          
          this.tindi[0]= this.tindi[0]+parseFloat(item.Pagos_efectivo); 
          this.tindi[1]= this.tindi[1]+parseFloat(item.valor_efectivo); 
          this.tindi[2]= this.tindi[2]+parseFloat(item.Pagos_tarjeta); 
          this.tindi[3]= this.tindi[3]+parseFloat(item.valor_tarjeta); 
          this.tindi[4]= this.tindi[4]+parseFloat(item.Pagos_mixto); 
          this.tindi[5]= this.tindi[5]+parseFloat(item.valor_mixto); 
          this.tindi[6]= this.tindi[6]+parseFloat(item.Pagos_empresario); 
          this.tindi[7]= this.tindi[7]+parseFloat(item.valor_empresario); 
          this.tindi[8]= this.tindi[8]+parseFloat(item.Pagos_cortesia); 
          this.tindi[9]= this.tindi[9]+parseFloat(item.Pagos_total); 
          this.tindi[10]= this.tindi[10]+parseFloat(item.total);  
 
        });


        this.$store.state.datosreporte.palcos.forEach((item, index) => {
          
          this.tpalco[0]= this.tpalco[0]+parseFloat(item.Pagos_efectivo); 
          this.tpalco[1]= this.tpalco[1]+parseFloat(item.valor_efectivo); 
          this.tpalco[2]= this.tpalco[2]+parseFloat(item.Pagos_tarjeta); 
          this.tpalco[3]= this.tpalco[3]+parseFloat(item.valor_tarjeta); 
          this.tpalco[4]= this.tpalco[4]+parseFloat(item.Pagos_mixto); 
          this.tpalco[5]= this.tpalco[5]+parseFloat(item.valor_mixto); 
          this.tpalco[6]= this.tpalco[6]+parseFloat(item.Pagos_empresario); 
          this.tpalco[7]= this.tpalco[7]+parseFloat(item.valor_empresario); 
          this.tpalco[8]= this.tpalco[8]+parseFloat(item.Pagos_cortesia); 
          this.tpalco[9]= this.tpalco[9]+parseFloat(item.Pagos_total); 
          this.tpalco[10]= this.tpalco[10]+parseFloat(item.total);  
 
        });


        
        this.$store.state.datosreporte.palcosindividual.forEach((item, index) => {
          
          this.tpalcoindt[0]= this.tpalcoindt[0]+parseFloat(item.Pagos_efectivo); 
          this.tpalcoindt[1]= this.tpalcoindt[1]+parseFloat(item.valor_efectivo); 
          this.tpalcoindt[2]= this.tpalcoindt[2]+parseFloat(item.Pagos_tarjeta); 
          this.tpalcoindt[3]= this.tpalcoindt[3]+parseFloat(item.valor_tarjeta); 
          this.tpalcoindt[4]= this.tpalcoindt[4]+parseFloat(item.Pagos_mixto); 
          this.tpalcoindt[5]= this.tpalcoindt[5]+parseFloat(item.valor_mixto); 
          this.tpalcoindt[6]= this.tpalcoindt[6]+parseFloat(item.Pagos_empresario); 
          this.tpalcoindt[7]= this.tpalcoindt[7]+parseFloat(item.valor_empresario); 
          this.tpalcoindt[8]= this.tpalcoindt[8]+parseFloat(item.Pagos_cortesia); 
          this.tpalcoindt[9]= this.tpalcoindt[9]+parseFloat(item.Pagos_total); 
          this.tpalcoindt[10]= this.tpalcoindt[10]+parseFloat(item.total);  
 
        });

        

       this.totalsinservivio=       parseFloat( this.$store.state.datosreporte.ventas.monto_efectivo) + parseFloat(this.$store.state.datosreporte.ventas.monto_tarjeta) +parseFloat(this.$store.state.datosreporte.ventas.monto_mixto) +parseFloat(this.$store.state.datosreporte.ventas.monto_empresario) ;
          

       
        console.log(this.$store.state.datosreporte  );
      }
    },
    
  }
}
</script>
<style>
  .filter-informe-eventos{
    display: flex;
    flex-wrap: wrap;
    background-color: white;
    padding: 1rem;
    border-radius: 10px;
    box-shadow: 0 0 5px 1px rgb(219, 219, 219);
  }
  .filter-informe-eventos .v-menu__content{
    top: 35px !important;
    left: 0 !important;
  }
  .container-title-informe{
    width: 100%;
    border-bottom: 1px solid #cacaca;
    margin-bottom: 2rem;
  }
  .v-date-picker-table{
    height: 100% !important;
  }
  .v-table__overflow{
    max-height: none !important;
    overflow-y: hidden !important;
  }
  .v-datatable thead tr th:first-child{
    min-width: 300px;
  }
</style>